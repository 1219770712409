<template >
  <div>
    <br>
    <b-row>
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="person-square" font-scale="1.5"/> Configuración de la Cuenta</h4></b-col>
      <br><br>
    </b-row>
    <b-row>
      <b-col class="card-shadow">
        <b-row>
          <b-col cols="12"><br></b-col>
          <b-col><div class="error" v-if="datosCuenta.nombre==null&&datosCuenta.id==null">Tu Nombre y tu ID serán registrados una vez que hayas firmado tu primer documento con tu Firma Electrónica Avanzada</div></b-col>
          <!----------- Nombre --------------->
          <b-col cols="12"  style="margin-top:10px;">
            <h6 :style="styleLabelColor" class="text-bold">Nombre:</h6>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="person"></b-icon>
              </b-input-group-prepend>
              <b-form-input disabled  type="text" v-model="datosCuenta.nombre"></b-form-input>
            </b-input-group>      
          </b-col>
          <!----------- Id --------------->
          <b-col cols="12"  style="margin-top:10px;">
            <h6 :style="styleLabelColor" class="text-bold">ID:</h6>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="ui-radios-grid"></b-icon>
              </b-input-group-prepend>
              <b-form-input disabled  type="text" v-model="datosCuenta.id"></b-form-input>
            </b-input-group>      
          </b-col>
          <!----------- Correo principal --------------->
          <b-col cols="12"  style="margin-top:10px;">
            <h6 :style="styleLabelColor" class="text-bold">Correo principal:</h6>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="envelope"></b-icon>
              </b-input-group-prepend>
              <b-form-input disabled  type="email" v-model="datosCuenta.correoPrincipal"></b-form-input>
            </b-input-group>      
          </b-col>  
          <!----------- Correos alternos --------------->
          <b-col>
            <br>
            <b-button block v-b-toggle="'collapse-calternos'" variant="secondary" style="text-align:left;"><b-icon  icon="envelope" ></b-icon> Correos Alternos</b-button>
            <b-collapse  id="collapse-calternos" style="width: 100%;"  role="tabpanel">
              <b-row>
                <b-col cols="12"><br></b-col>
                <b-col cols="12"><h5 v-if="datosCuenta.correosAlternos.length==0" :style="styleLabelColor" class="text-bold">Aún no tienes correos alternos registrados</h5></b-col>
                <b-col cols="12"><h5 v-if="datosCuenta.correosAlternos.length>0" :style="styleLabelColor" class="text-bold">Correos registrados:</h5></b-col>
                <b-col cols="12"><h6 v-for="(correo) in datosCuenta.correosAlternos" :key="correo" >{{correo}} <b-badge pill variant="danger" href="#" @click="eliminarCorreoAlterno(correo)">Eliminar</b-badge></h6></b-col>
                <b-col><br></b-col>
                <b-col cols="12"><h5 :style="styleLabelColor" class="text-bold">Registra un correo electrónico</h5></b-col>
                <b-col cols="12" sm="9" md="8" lg="9" xl="9">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <b-icon icon="envelope"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input  type="email" v-model="correoNuevo" placeholder="Ingresa un correo electrónico"></b-form-input>
                  </b-input-group>      
                </b-col>
                <b-col cols="12" sm="3" md="4" lg="3" xl="3"><b-button block :variant="principalVariant" size="sm" @click="agregarCorreoAlterno">Registrar correo</b-button></b-col>
                <b-col cols="12"><br></b-col>

                <b-col cols="12"><h6>Si quieres saber más consulta <span @click="verInfoCorreoAlterno" href="#" :style="styleLabelColor" class="text-bold">¿Qué es un correo alterno?</span></h6></b-col>
              </b-row>
            </b-collapse>
          </b-col>
          <b-col cols="12"><br></b-col>
        </b-row>

      </b-col>
      

    </b-row>
  </div>
</template>

<script>
  //import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  export default {
    computed: {
      ...mapState({
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
        styleLabelColor: state => state.user.styleLabelColor,
        baseColor: state => state.user.baseColor,
        datosCuenta: state => state.user.datosCuenta,
      }),
      
    },
    
    components: {
      
    },
    data() {
      return {
        correoNuevo:""
      }
    },
    methods: { 
      agregarCorreoAlterno(){
        alert(this.correoNuevo)
      },
      eliminarCorreoAlterno(correo){
        alert(correo)
      },
      verInfoCorreoAlterno(){
        this.$bvModal.msgBoxOk('Si usas más de un correo electrónico para firmar documentos, puedes registrarlos como correos alternos y acceder a los documentos ligados a ellos desde esta cuenta. Considera que si registras un correo como correo alterno, no podrás usarlo para crear una cuenta nueva, para ello necesitarás primero darlo de baja de esta lista. Tampoco puedes registrar correos que ya esten ligados a otra cuenta', {
          title: 'Qué es un correo alterno?',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(() => {
          
          })
          .catch(() => {
            // An error occurred
          })
      }
    },
    validations: {
      
    }
  }
</script>
<style>
</style>