<template >
  <div>
    <br>
    <b-row>
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="cart-check" font-scale="1.5"/> Pagos y Facturación</h4></b-col>
      <br><br>
    </b-row>
    <b-row>
      <b-col class="card-shadow">
        <br>
        <b-row>
          <b-col v-if="pagosCuenta.creditos!=1" cols="12"><h5  class="text-bold">Tienes {{pagosCuenta.creditos}} documentos por utilizar</h5></b-col>
          <b-col v-if="pagosCuenta.creditos==1" cols="12"><h5  class="text-bold">Tienes {{pagosCuenta.creditos}} documento por utilizar</h5></b-col>
          <b-col cols="12"><br></b-col>
          <b-col>
            <b-button block v-b-toggle="'collapse-compra'" :variant="principalVariant" style="text-align:left;"><b-icon  icon="minecart-loaded" ></b-icon> Comprar Documentos</b-button>
            <b-collapse  id="collapse-compra" style="width: 100%;"  role="tabpanel">
              <b-row>
                <b-col cols="12"><br></b-col>
                <b-col><b-input type="number" step="1"></b-input></b-col>
                <b-col>
                  <b-form-radio-group
                    v-model="planPago"
                    :options="opcionesPago"
                  ></b-form-radio-group>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-collapse> 
            <br>
          </b-col>
        </b-row>
      </b-col>      
    </b-row>
    <br>
    <b-row>
      <b-col class="card-shadow" >    
        <b-row  align-h="center" align="center">
          <b-col cols="12">
            <br>
            <h6 :style="styleLabelColor" class="text-bold">Pagos Realizados</h6>
          </b-col>
          <b-col>
            <b-table responsive stacked="md" striped small sticky-header :items="pagosCuenta.pagos" :fields="fieldsPagos" class="table3class" sort-icon-left>
              <template v-slot:cell(acciones)="row">
                <b-button v-if="row.item.facturado=='No'" pill variant="success" size="sm" @click="facturarPago(row.item.ordenPago)">Facturar</b-button>
                <b-button v-if="row.item.facturado=='Si'" pill variant="info" size="sm" @click="verFactura(row.item.ordenPago)">Ver Factura</b-button>
              </template>
            </b-table>
            <br>
          </b-col>  
        </b-row>
      </b-col>
    </b-row>
    <br>
    
  </div>
</template>

<script>
  //import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  export default {
    computed: {
      ...mapState({
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
        styleLabelColor: state => state.user.styleLabelColor,
        baseColor: state => state.user.baseColor,
        pagosCuenta: state => state.user.pagosCuenta,
      }),
      estadosOptions: function () {
        return this.$estadosDocumento
      }
    },
    
    components: {
      
    },
    data() {
      return {
        fieldsPagos:[
          {
            key: 'ordenPago',
            label: 'No. de Orden',
            sortable: false,
          },
          {
            key: 'fechaPago',
            label: 'Fecha de Pago',
            sortable: false,
          },
          {
            key: 'concepto',
            label: 'Concepto',
            sortable: false,
          },
          {
            key: 'monto',
            label: 'Monto (MXN)',
            sortable: false,
          },
          {
            key: 'facturado',
            label: 'Facturado',
            sortable: false,
          },
          {
            key: 'acciones',
            label: 'Accciones'
          }
        ],
        planPago:'',
        opcionesPago : [
          { text: '10 documentos $599.00', value: '1'},
          { text: '30 documentos Item 2', value: 'second', disabled: false },
          { html: '<b>Item</b> 3', value: 'third', disabled: true },
          { text: 'Item 4', value: 'Item 4', disabled: false },
          { text: 'Item 5', value: 'E', disabled: false }
        ],
      }
    },
    methods: { 
      facturarPago(ordenPago){
        alert(ordenPago)
      },
      verFactura(ordenPago){
        alert(ordenPago)
      }
    },
    validations: {
      
    }
  }
</script>
<style>
	.table2class{
    font-size:small;
  }
</style>