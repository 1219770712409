<template >
  
  <b-container fluid style="padding-left:0px; padding-right:0px;">
    <nav-app></nav-app>
    <Loading :active.sync="isWorking" :is-full-page="true"></Loading>
    <b-row v-if="perfilUsuario==null && perfilUsuarioPublico==null" align-h="center" no-gutters >
      <b-col class="border-free card-shadow" cols="12" sm="8" md="6" lg="5" xl="3"> 
          <Login ></Login>
      </b-col>
    </b-row>
    <b-row v-if="perfilUsuario!=null || perfilUsuarioPublico!=null" align-h="center" no-gutters >
      <b-col style="margin-left:30px; margin-right:15px;" cols="12" sm="12" md="10" lg="8" xl="6">        
          <Perfil></Perfil>
      </b-col>
    </b-row>
    <b-row  no-gutters>
      <b-col>
        <br><br><br><br>
        <!--Footer></Footer>-->
      </b-col>
    </b-row>
    <b-modal v-model="verMsgUsuario" hide-footer hide-header >
      <Mensaje> </Mensaje>
    </b-modal>
  </b-container>
</template>

<script>
  import NavApp from './NavApp.vue'
  import Mensaje from './Mensaje.vue'
  import Login from './Login.vue'
  import Perfil from './Perfil.vue'
  //import Footer from './Footer.vue'
  import { mapState} from 'vuex';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {obtenerDocumentoPublico} from '@/axios-tools'
  export default {
    computed: {
      ...mapState({
        perfilUsuario: state => state.user.perfilUsuario,
        perfilUsuarioPublico: state => state.user.perfilUsuarioPublico,
        isWorking: state => state.user.isWorking,
      }),
      verMsgUsuario: {
        get: function() {
          return this.$store.state.user.verMsgUsuario;
        },
        set: function(ver) {
          this.$store.commit("updateVerMsgUsuario", ver);
        }
      },
    },
    components:{
      Mensaje,
      Login,
      Perfil,
      Loading,
      NavApp,
      //Footer
    },
    mounted() {
      /*Si la ruta es la de consulta publica se obtiene el usuario publico y el documento a consultar*/ 
      if(this.$route.query.doc!=null){
        var version = this.$route.query.doc
        var doc = this.$route.query.doc
        var participante = this.$route.query.part
        obtenerDocumentoPublico(this.$store, version, doc, participante);
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
    }
  }
</script>
<style >
</style>