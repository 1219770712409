import * as jsPDF from 'jspdf';
//import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import QRCode from'davidshimjs-qrcodejs';

function datatBynaryToOctet(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}

function generarPdf(contenidoOrdenPago, store){
    var detalle = contenidoOrdenPago.detalle;
    var municipio = contenidoOrdenPago.municipio;
    var fecha = contenidoOrdenPago.fecha;
    var fechav = contenidoOrdenPago.fechav;
    var total = contenidoOrdenPago.total;
    var folio = contenidoOrdenPago.folio;
    var qrCodi = contenidoOrdenPago.qrCodi;
    var formasPago = [contenidoOrdenPago.formasPago.length];
    for (var idxFormasPago in contenidoOrdenPago.formasPago){
        formasPago[idxFormasPago] = [contenidoOrdenPago.formasPago[idxFormasPago].nombre  + " - " + contenidoOrdenPago.formasPago[idxFormasPago].ref];
    }
    var parcialidades = [contenidoOrdenPago.parcialidades.length];
    for (var index in contenidoOrdenPago.parcialidades){
        parcialidades[index] = [ 
            contenidoOrdenPago.parcialidades[index].periodo, 
            contenidoOrdenPago.parcialidades[index].importe, 
            contenidoOrdenPago.parcialidades[index].recargo, 
            contenidoOrdenPago.parcialidades[index].multa, 
            contenidoOrdenPago.parcialidades[index].pae, 
            contenidoOrdenPago.parcialidades[index].subsidios, 
            contenidoOrdenPago.parcialidades[index].total ];
    }
    var pdfGenerated = genPdfLinea(detalle, parcialidades, total, formasPago, true, fecha, fechav, folio, contenidoOrdenPago.logoMpio2, qrCodi, municipio);
    store.commit("updatePdfGenerated", pdfGenerated);
    store.commit("updateIsShowModalPdfLinea", true);
}

function genPdfLinea(detalle, parcialidades, totalLiquidacion, formasPago, conReferencia, fecha, fechav, folio, logo, qrCodi, municipio){
    var doc = new jsPDF({orientation: 'portrait', unit: 'mm',format: 'letter'});
    var color_tem;
    /*var municipio = 'teotihuacan';*/
    if(municipio != null & municipio == 'teotihuacan'){
        color_tem = ['#9D2449',157,36,73];
    }else if(municipio != null & municipio == 'demo'){//216,29,0-D81D00
        color_tem = ['#9D2449',157,36,73];
    }else{
        color_tem = ['#9D2449',157,36,73];
    }

    if(conReferencia){
        doc.setFontSize(16);
        doc.setFontType("bold");
        doc.text("FORMATO DE PAGO REFERENCIADO", 100, 22);
        doc.setFontType("normal");

        var imgData = 'data:image/jpeg;base64,' + logo;
        doc.addImage(imgData, 'JPEG', 15, 4, 60, 30);
        doc.setFillColor(color_tem[1],color_tem[2],color_tem[3]);
        doc.rect(14, 35, 189, 0.7, 'F');

        doc.setDrawColor(color_tem[1],color_tem[2],color_tem[3]);
        doc.setLineWidth(0.7);
        doc.rect(125, 40, 78, 31);
        doc.setFontSize(16);
        doc.setTextColor(0,0,0);
        doc.text("Serie: ", 150, 47, 'right');
        doc.setFontType("bold");
        doc.text(folio.substring(0,3), 163, 47, 'right');
        doc.setFontType("normal");
        doc.text("Folio: ", 182, 47, 'right');
        doc.setFontType("bold");
        doc.text(folio.substring(3,9), 200, 47, 'right');
        doc.setFontType("normal");

        doc.setFontSize(12);
        doc.text("Válido hasta: " + fechav, 200, 53, 'right');
    }

    doc.setFontSize(18);
    doc.text("Total a Pagar:", 199, 61, 'right');
    doc.setTextColor(160,2,24);
    doc.text("$"+totalLiquidacion, 200, 68, 'right');
    doc.setTextColor(0,0,0);

    doc.setFillColor(0,0,0);
    doc.setFontSize(16);
    doc.autoTable({
        styles: {fillColor:'#FFFFFF',textColor:'#000000', font:'helvetica', fontStyle: 'bold', halign: 'center', lineWidth: 0.6, lineColor:[color_tem[1],color_tem[2],color_tem[3]]},
        theme:'plain',
        headStyles:{fillColor:color_tem[0], textColor:'#FFFFFF'},
        bodyStyles: {fillColor:'#FFFFFF', cellPadding:{top: 2, right: 2, bottom: 2, left: 2}},
        startY:40,
        tableWidth: 101,
        margin: {top: 20},
        head: [['Datos del Pago']],
        body: [[detalle]],
        columnStyles: {0: {halign: 'center'}}
    });

    /* Cálculo del total de Páginas */
    var totalPag = 1;
    if(parcialidades.length > 14 && parcialidades.length < 24){
        totalPag ++;
    }else if(parcialidades.length >= 24 && parcialidades.length <= 45){
        totalPag ++;
    }else if(parcialidades.length > 45){
        totalPag=totalPag+2;
    }
    doc.setFillColor(color_tem[1],color_tem[2],color_tem[3]);
    doc.rect(14, 270, 189, 0.7, 'F');
    doc.setFontSize(7);
    if(fecha != null){
        doc.text("Fecha y hora de generación: "+fecha, 15, 273);
    }
    doc.text("Página 1 de " + totalPag, 187, 273);

    doc.setFontType("bold");
    doc.setFontSize(16);
    doc.text("Liquidación", 105, 85, 'center');
    doc.setFontType("normal");
    doc.autoTable({
        styles: {textColor:'#000000', font: 'helvetica',fontStyle: 'bold', halign: 'center'},
        headStyles:{fillColor:color_tem[0], textColor:'#FFFFFF'},
        bodyStyles: {fillColor:'#FFEBF1'},
        startY:87,
        margin: {top: 20},
        head: [['Periodo','Importe','Recargo','Multa','Pae','Subsidios','Total']],
        body: parcialidades,
        columnStyles: {0: {halign: 'center'}} 
    });

    if(conReferencia){

        /* Cálculo del eje 'Y' en función del número de parcialidades */
        var ejeY = 112 + ((parcialidades.length)*7);
        if(parcialidades.length > 14 && parcialidades.length < 24){
            doc.addPage();
            doc.setFillColor(0,0,0);
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.setFontSize(7);
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 2 de " + totalPag, 187, 273);
            ejeY = 15;
            totalPag ++;
        }else if(parcialidades.length >= 24 && parcialidades.length <= 45){
            doc.setFillColor(0,0,0);
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.setFontSize(7);
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 2 de " + totalPag, 187, 273);
            ejeY = 43 + ((parcialidades.length-22)*7);
        }else if(parcialidades.length > 45 && parcialidades.length < 55){
            doc.setFillColor(0,0,0);
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.setFontSize(7);
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 2 de " + totalPag, 187, 273);
            doc.addPage();
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 3 de " + totalPag, 187, 273);
            ejeY = 15;
        }else if(parcialidades.length >= 55){
            doc.setPage(2);
            doc.setFillColor(0,0,0);
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.setFontSize(7);
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 2 de " + totalPag, 187, 273);
            doc.setPage(3);
            doc.setFillColor(0,0,0);
            doc.rect(14, 270, 189, 0.7, 'F');
            doc.setFontSize(7);
            doc.text("Fecha y hora de generación: "+fecha, 15, 273);
            doc.text("Página 3 de " + totalPag, 187, 273);
            ejeY = 50 + ((parcialidades.length-53)*7);
        }

        /*var qrCodi = "informacion de ejemplo";*/
        var widthTableFormasPago='auto';
        if(qrCodi != null){
            doc.setFontType("bold");
            doc.setFontSize(16);
            doc.text("CoDi", 179, ejeY+8, 'center');
            doc.setFontSize(9);
            doc.text("Paga desde tu celular", 179, ejeY+11,'center');
            var divQr = document.createElement("div");
            new QRCode(divQr, qrCodi);
            const src = divQr.children[0].toDataURL("image/png");
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.6);
            doc.rect(158, ejeY+2, 43, 60);
            doc.addImage(src,'PNG', 160.10, ejeY+13, 38.85, 40);
            doc.text("Escanea desde tu", 179, ejeY+57,'center');
            doc.text("aplicación bancaría", 179, ejeY+60,'center');
            widthTableFormasPago=134;
        }

        doc.setFontSize(16);
        doc.text("Formas de Pago", 105, ejeY,'center');
        doc.setFontType("normal");
        ejeY = ejeY + 2;
        doc.setFontSize(12);
        doc.autoTable({
            styles: {textColor:'#000000', font: 'helvetica',fontStyle: 'bold', lineWidth: 0.6, lineColor:[color_tem[1],color_tem[2],color_tem[3]]},
            theme:'plain',
            bodyStyles: {fillColor:'#FFFFFF', cellPadding:{top: 3, right: 3, bottom: 2.5, left: 3}},
            headStyles:{fillColor:color_tem[0], textColor:'#FFFFFF',halign: 'center'},
            tableWidth: widthTableFormasPago,
            startY:ejeY,
            margin: {top: 20},
            head: [['Forma de Pago']],
            body: formasPago,
            columnStyles: {0: {halign: 'left'}}
        });
    }

    return URL.createObjectURL(doc.output('blob'));
}

function handleErrorUser(err, store){
    var status = err.response ? err.response.status : 0;
    if(status==0){
        store.commit("updateMsgUsuario", {codigo:"1090", mensaje:"La petición no pudo ser enviada, revise su conexión a internet o contacte al provedor del sistema", causa:""});
    }else if(status==400){
        store.commit("updateMsgUsuario", err.response.data);
    }else if(status==500 ){
        store.commit("updateMsgUsuario", {codigo:"1091", mensaje:"El sistema no se encuentra disponible. Intente más tarde", causa:""});
    }
    store.commit("updateVerMsgUsuario", true);
    
}


function downloadFileToB64(b64, type, fileName){
    var linkSource = 'data:application/octet-stream;base64,'+b64;
    var downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName+'.'+type;
    downloadLink.click();
}

export {downloadFileToB64, datatBynaryToOctet, handleErrorUser, generarPdf};