<template >
  <b-container fluid >
    <b-row align-h="center" no-gutters>
      <b-col  cols="12">              
        <div  @dragover.prevent @drop.prevent>          
          <b-card class="box-drag" @dragleave="fileDragOut" @dragover="fileDragIn" @drop="handleFileDrop"  v-bind:style="{ 'background-color': color }">
            <b-row align="center" no-gutters>
              <b-col cols="12" style="margin-top:20px; margin-bottom:20px">
                <p class="h1 "><b-icon scale="2" icon="download" :variant="principalVariant"></b-icon></p>
              </b-col>
              <b-col cols="12">
                  <span>Arrastra aquí un archivo PDF para firmarlo</span>
              </b-col>
            </b-row><br>
            <b-row align="center" no-gutters>
              <b-col >
                <div  class="file-wrapper" v-bind:style="{ 'background-color': color }">
                  <input type="file" name="file-input" multiple="False" @change="handleFileInput" > 
                  <b-button pill variant="warning">o click aqui para seleccionar uno</b-button>
                </div>
                <!---
                <ul>
                    {{ file.name }} ({{ file.size }} b)
                    <button @click="removeFile()" title="Remove">X</button>
                </ul>--->
              </b-col>
            </b-row>            
          </b-card>
        </div>
      </b-col> 
    </b-row>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  export default {
    computed: {
      ...mapState({
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
      }),
      doc: {
        get: function() {
          //Si esta encriptado aqui se desencripta
          return this.$store.state.user.docNvo;
        }
      }
    },
    components:{
    },
    delimiters: ['${', '}'], // Avoid Twig conflicts
    data() {
      return {
        isShowModal:false,
        file:null,
        color: '#e8e8e8',
      }
    },
    methods: {
      handleFileDrop(e) {
        let droppedFiles = e.dataTransfer.files;
        if(!droppedFiles) return;      
        this.file=droppedFiles[0];
        this.color="#e8e8e8"
        this.guardarArchivo(this.file);
      },
      handleFileInput(e) {
        let files = e.target.files;
        files = e.target.files
        if(!files) return;
        this.file=files[0];     
        this.guardarArchivo(this.file); 
      },
      removeFile(){
        this.file="";
      },
      fileDragIn(){
        // alert("oof")
        // alert("color")
        this.color="#ccccff"
      },
      fileDragOut(){
        this.color="#e8e8e8"
      },
      guardarArchivo(file){
        this.$store.commit("updateIsWorking", true);
        var reader = new FileReader();
        //Creamos una refrencia local al objeto this para poder acceder desde las funciones anidadas a los objetos cliente, token .. etc
        var thisObject = this;
        reader.onload = (function() {
          return function(theFile) {
            var binaryData = theFile.target.result;
            // Convertir datos binarios a base 64
            var base64String = window.btoa(binaryData);
            // mostrando el archivo convertido a base64
            var tipo = file.name.split('.').pop();
            if(tipo == 'pdf' ){
              if(file.size <= 20000000){
                thisObject.$store.commit("updateArchivoInDocNvo", {archivo:base64String, nombre:file.name});
              }else{
                thisObject.$store.commit("updateMsgUsuario", {codigo:"1092", mensaje:"Tu archivo es muy grande y no es posible procesarlo", causa:"Contáctanos vía chat decirte cómo firmar archivos grandes."});
                thisObject.$store.commit("updateVerMsgUsuario", true);
              }
            }else{
              thisObject.$store.commit("updateMsgUsuario", {codigo:"1092", mensaje:"Tu archivo seleccionado no es un PDF y no se puede procesar", causa:"Contáctanos vía chat para decirte como puedes firmar archivos que no son PDF."});
              thisObject.$store.commit("updateVerMsgUsuario", true);
            }
            thisObject.$store.commit("updateIsWorking", false);
          };
        })(file);
        reader.onerror = function(error){
          alert("Error al cargar archivo: "+error);
          this.$store.commit("updateIsWorking", false);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(file);
      }
    }
  }
</script>
<style>
.box-drag {
  border: 2px dashed black;
}
.file-wrapper {
  text-align: center;  
  vertical-align: middle;
  display: table-cell;
  position: relative;
  overflow: hidden;
  
}


.file-wrapper input {
    position: absolute;
    top: 0;
    right: 0; /* not left, because only the right part of the input seems to
                 be clickable in some browser I can't remember */
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=0); /* and all the other old opacity stuff you
                                 want to support */
    font-size: 300px; /* wtf, but apparently the most reliable way to make
                         a large part of the input clickable in most browsers */
    
}
</style>