import axios from 'axios';
import { handleErrorUser} from '@/vue-tools';
//import { isTokenUserValid, isTokenAdminValid, isTokenClientValid } from '@/auth-tools'


//const url = "http://192.168.1.65:9001/"
//const url = "http://localhost:9000/"
//const url = "http://192.168.1.65:9000/"
const url = "https://firmamx.appspot.com/"

function loginUser(store, idToken, correo, pass, tipoSocial){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'login',
        data: {
            "idToken": idToken,
            "correo" : correo,
            "pass" : pass,
            "tipoSocial" : tipoSocial
        }
    })
    .then(res => {
        store.commit("updatePerfilUsuario", res.data[0]);
        store.commit("configVistaPerfil", {verDocumentoIndividual:true});
        store.commit("updateToken", res.data[1]);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store); 
        store.commit("updateIsWorking", false);
    });
}

function recuperarContraseña(store, correo, captcha){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'recuperapass',
        params: {
            "correo" : correo,
            "captcha" : captcha,
        }
    })
    .then(() => {
        //asignamos un codigo dentro del rango de los codigos de error para que al usuario se le muestra la opcion de Tienes dudas? Contactanos
        store.commit("updateMsgUsuario", {codigo:"1999", mensaje:"Hemos enviado una contraseña temporal a tu correo electrónico", causa:"Una vez que ingreses al sistema debes asignar una nueva."});
        store.commit("updateVerMsgUsuario", true);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}
function agregarVerificarParticipante(store, token, nombre, correo, rfc, tipoParticipante, numEtapa, tipoFirma){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'verificafirma',
        headers: {'Authorization': token},
        data: {
            correo:correo,
            nombre:nombre,
            rfc:rfc
        }
    })
    .then((res) => {
        //Agregar la etapa
        res.data.numEtapa=numEtapa;
        res.data.tipoParticipante=tipoParticipante;
        res.data.tipoFirma=tipoFirma;
        store.commit("agregarParticipanteDocNvo", res.data);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}
function crearDocumento(store, token, documento, ){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        headers: {'Authorization': token},
        url: url + 'creardoc',
        data: documento
    })
    .then((res) => {
        store.commit("updateDocPublico", res.data);
        store.commit("updateMjeConfirmaProceso", "Tu documento se ha creado exitosamente.");
        store.commit("updateVerMjeConfirmaProceso", true);
        store.commit("configVistaPerfil", {verProcesoPublico:true});
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}
function firmarDocumento(store, idDocumento, partDto, msjExito, esFirmaDesdeMisDocumentos){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'firmadoc',
        data: partDto,
        params:{
            idDocumento: idDocumento
        }
    })
    .then((res) => {
        store.commit("updateDocPublico", res.data);
        store.commit("updateMjeConfirmaProceso", msjExito);
        store.commit("updateVerMjeConfirmaProceso", true);
        if(!esFirmaDesdeMisDocumentos){
            alert("No es firma desde mis docs")
            store.commit("configVistaPerfil", {verProcesoPublico:true});
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}
function obtenerDocumentoPublico(store, version, idDocumento, idParticipante){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'documento',
        params:{
            version: version,
            idDocumento: idDocumento,
            idParticipante: idParticipante
        }
    })
    .then((res) => {
        store.commit("updateDocPublico", res.data[0]);
        store.commit("updatePerfilUsuarioPublico", res.data[1]);
        store.commit("configVistaPerfil", {verProcesoPublico:true});
        store.commit("configCollapse", {verCollapseFirma:true, verCollapseDoc:true});
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function obtenerDocumentoUsuarioParaFirma(store, token, idDocumento){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        headers: {'Authorization': token},
        url: url + 'documentousuario',
        params:{
            idDocumento: idDocumento,
        }
    })
    .then((res) => {
        store.commit("updateDocPublico", res.data);
        store.commit('updateIsShowModalFirma', true)
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function obtenerDocumentoUsuarioParaAdmin(store, token, idDocumento, abrirVisor){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        headers: {'Authorization': token},
        url: url + 'documentousuario',
        params:{
            idDocumento: idDocumento,
        }
    })
    .then((res) => {
        store.commit("updateDocPublico", res.data);
        if(abrirVisor){
            store.commit('updateIsShowModalDocAdmin', true)
        }else{
            downloadFileToB64(res.data.archivo, 'pdf', res.data.nombre)
        }        
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function obtenerDocumentosCreados(store, token, tipoBusqueda, fecIni, fecFin, estado){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        headers: {'Authorization': token},
        params:{
            tipoBusqueda:tipoBusqueda,
            fecIni:fecIni,
            fecFin:fecFin,
            estado:estado
        },
        url: url + 'documentos',
    })
    .then((res) => {
        store.commit("updateDocsCreados", res.data);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function documentosPendientesFirma(store, token){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        headers: {'Authorization': token},
        url: url + 'docspendientes',
    })
    .then((res) => {
        store.commit("updateDocsPorFirmar", res.data);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function borrarDocumento(store, token, idDocumento){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        headers: {'Authorization': token},
        params:{
            idDocumento:idDocumento
        },
        url: url + 'borrardoc',
    })
    .then(() => {
        store.commit("quitarDocumentoFromCreados", idDocumento);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}
/************/
function downloadFileToB64(b64, type, fileName){
    var linkSource = 'data:application/octet-stream;base64,'+b64;
    var downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName+'.'+type;
    downloadLink.click();
}

export { loginUser, recuperarContraseña, agregarVerificarParticipante, crearDocumento, firmarDocumento, obtenerDocumentoPublico, obtenerDocumentoUsuarioParaFirma, documentosPendientesFirma, obtenerDocumentosCreados, obtenerDocumentoUsuarioParaAdmin, borrarDocumento}