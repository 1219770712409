<template >
  <b-container fluid>
    <b-row align-h="center" align="center">
      <b-col>
        <b-row>
          <b-col cols="12">
            <h5>
              {{msgUsuario.mensaje}}
            </h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <h5>
              {{msgUsuario.causa}}
            </h5>
          </b-col>
        </b-row>

        <b-row v-if="msgUsuario.codigo<2000">
          <b-col cols="12">
            <br>
            <h6 :style="styleLabelColor">Necesitas ayuda? Contáctanos por el chat.</h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button pill :variant="secondaryVariant" @click="cerrarMsgUsuario">Entendido</b-button>
            <br>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  export default {
    computed: {
      ...mapState({
        styleLabelColor: state => state.user.styleLabelColor,
        secondaryVariant: state => state.user.secondaryVariant,
      }),
      msgUsuario: {
        get: function() {
          return this.$store.state.user.msgUsuario;
        },
        set: function(newME) {
          this.$store.commit("updateMsgUsuario", newME);
        }
      },
    },
    components:{
    },
    data() {
      return {
        
      }
    },
    methods: {
      cerrarMsgUsuario(){
        this.$store.commit("updateVerMsgUsuario", false);
      }
    }
  }
</script>
<style>
</style>