<template >
  <div>
    <b-row v-if="verProcesoPublico" no-gutters>
      <b-col>      
        <proceso-publico esFirmaDesdeMisDocumentos="false"></proceso-publico>
      </b-col>      
    </b-row>
    <b-row v-if="verDocumentoIndividual" no-gutters>
      <b-col>      
        <DocumentoIndividual></DocumentoIndividual>
      </b-col>      
    </b-row>
    <b-row v-if="verDocumentosMasivos" no-gutters>
      <b-col>      
        <documentos-masivos></documentos-masivos>
      </b-col>      
    </b-row>
    <b-row v-if="verDocumentosPendientes" no-gutters>
      <b-col>      
        <documentos-por-firmar></documentos-por-firmar>
      </b-col>      
    </b-row>
    <b-row v-if="verDocumentosCreados" no-gutters>
      <b-col>      
        <documentos-creados></documentos-creados>
      </b-col>      
    </b-row>
    <b-row v-if="verDatosCuenta" no-gutters>
      <b-col>      
        <datos-cuenta></datos-cuenta>
      </b-col>      
    </b-row>
    <b-row v-if="verPagos" no-gutters>
      <b-col>      
        <pagos-cuenta></pagos-cuenta>
      </b-col>      
    </b-row>
    <b-row v-if="verApi" no-gutters>
      <b-col>      
      </b-col>      
    </b-row>
  </div>
</template>

<script>
  //import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  import ProcesoPublico from './ProcesoPublico.vue'
  import DocumentoIndividual from './DocumentoIndividual.vue'
  import DocumentosMasivos from './DocumentosMasivos.vue'
  import DocumentosPorFirmar from './DocumentosPorFirmar.vue'
  import DocumentosCreados from './DocumentosCreados.vue'
  import DatosCuenta from './DatosCuenta.vue'
  import PagosCuenta from './PagosCuenta.vue'
  export default {
    computed: {
      ...mapState({
        verProcesoPublico: state => state.user.verProcesoPublico,
        verDocumentoIndividual: state => state.user.verDocumentoIndividual,
        verDocumentosMasivos: state => state.user.verDocumentosMasivos,
        verDocumentosPendientes: state => state.user.verDocumentosPendientes,
        verDocumentosCreados: state => state.user.verDocumentosCreados,
        verDatosCuenta: state => state.user.verDatosCuenta,
        verPagos: state => state.user.verPagos,
        verApi: state => state.user.verApi,
      }),
      docNvo: {
        get: function() {
          return this.$store.state.user.docNvo;
        }
      },
    },
    
    components: {
      DocumentoIndividual,
      DocumentosMasivos,
      ProcesoPublico,
      DocumentosPorFirmar,
      DocumentosCreados,
      DatosCuenta,
      PagosCuenta
    },
    data() {
      return {
        tabPerfil:0,
      }
    },
    methods: { 
      linkClass(idx) {
        if (this.tabPerfil === idx) {
          return ['text-dark',"font-weight-bold", "border-tab"]
        } else {
          return ['text-dark']
        }
      },
      crearNuevoDoc(){
        this.$store.commit("updateDocNvo");
      }
    },
    validations: {
      
    }
  }
</script>
<style>

</style>