<template >
  <div style="margin-top:10px;">
    <div  class="card-part"  >
      <b-row  no-gutters  @click="expandir=!expandir" >         
          <b-col cols="11" sm="11" md="11" lg="11" xl="11">
            <h6><b-icon :variant="principalVariant" icon="person-fill"></b-icon><span> {{participante.nombre}}</span></h6>
          </b-col>            
          <b-col>
            <b-row align="right" no-gutters>
              <b-col>
                <b-icon h-ref="#" v-if="!expandir" variant="secondary" scale="2" icon="chevron-compact-down"></b-icon>
                <b-icon h-ref="#" v-if="expandir"  variant="secondary" scale="2" icon="chevron-compact-up"></b-icon>
              </b-col>
            </b-row>
          </b-col>
      </b-row> 

      <b-row v-if="expandir" no-gutters>         
          <b-col cols="12" sm="5" md="5" lg="5" xl="5">
            <b-icon :variant="principalVariant" icon="envelope-fill"></b-icon>
            <span> {{participante.correo}}</span>
          </b-col>   
          <b-col cols="12" sm="4" md="4" lg="4" xl="4" v-if="participante.rfc!=null&&participante.rfc!=''">
            <b-icon :variant="principalVariant" icon="grid-fill"></b-icon>
            <span> {{participante.rfc}}</span>
          </b-col>
          <b-col cols="12" sm="4" md="4" lg="4" xl="4" v-if="participante.tipoParticipante==this.$tipoAcuse">
            <b-icon :variant="principalVariant" icon="pen-fill"></b-icon>
            <span> Firma {{participante.tipoFirma}}</span>
          </b-col>
          <b-col cols="12" sm="8" md="8" lg="8" xl="8" v-if="participante.tipoParticipante!=this.$tipoObservador">
            <b-icon v-if="participante.fecFirma!=null" :variant="principalVariant" icon="calendar2-check-fill"></b-icon>
            <b-icon v-if="participante.fecFirma==null" :variant="principalVariant" icon="calendar2-x-fill"></b-icon>
            <span> {{participante.estado}}</span>
          </b-col>
          <b-col cols="7" sm="10" md="10" lg="10" xl="10">
            <b-badge h-ref="#" v-if="tipoVista=='nuevo'" @click="eliminarParticipante()" pill variant="danger">
              <b-icon h-ref="#"  icon="x-circle-fill"></b-icon>
              Eliminar
              </b-badge>
          </b-col>        
      </b-row>     
    </div> 
  </div>
</template>

<script>
  import { mapState} from 'vuex';
  export default {
    computed: {
      ...mapState({
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
        styleLabelColor: state => state.user.styleLabelColor,
      }),
      doc: function() {
          //Si esta encriptado aqui se desencripta
          return this.$store.state.user.docNvo;
        
      },
    },
    props: [
      'tipoVista',
      'participante'
    ],
    data() {
      return {
        expandir:false
      }
    },
    methods: { 
      eliminarParticipante(){
        //Si ya no hay participantes en la etapa tambien se borrar la etapa
        var numPartsEtapa=0;
        for(var p=0; p<this.doc.participantes.length; p++){
            if(this.doc.participantes[p].numEtapa == this.participante.numEtapa ){
              numPartsEtapa++;
            }
        }
        //si ya solo era el ultimo y no es la etapa 1 entonces tambie borramos la etapa solo si es firmante
        if(numPartsEtapa==1 && this.participante.numEtapa!=1 && this.participante.tipoParticipante=="Firmante"){
            this.eliminarEtapa(this.participante.numEtapa);
        }
        //Eliminar al ultimo
        this.$store.commit("eliminarParticipante", this.participante.correo);  
      },
      eliminarEtapa(numEtapa){  
        this.$store.commit("eliminarEtapaDocNvo", numEtapa);      
      }
    },
    validations: {
     
    }    
  }
</script>
<style>

</style>